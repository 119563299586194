.footer_link {
    color: #18216d;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
}
.footer_link:hover {
    color: #ff8c00;
}
.footer_span {
    margin-top: 10px;
    margin-bottom: 10px;
}