@media only screen and (max-width: 411px) {
    .ism_logo {
        display: none;
    }
    .ism_nav {
        display: none;
    }
}
@media only screen and (min-width: 412px) {
    .desktop_nav {
        display: none;
    }
}